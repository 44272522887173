import { BranchQuote } from '../../../api/dealer-api-interface-franchisee';
import {
  Quote,
  QuoteItem,
  QuoteItemBuyInData,
  QuoteItemPrice,
  QuoteItemProviderData,
  QuotePresentation,
  QuotePrice,
  QuoteSetSibling
} from '../../../api/dealer-api-interface-quote';
import { QuoteContainer } from '../../../quotes/data/quote-container';
import { QuoteProviderData } from '../../../quotes/data/quote-provider-data';

export class FranchiseeQuoteContainer extends QuoteContainer {
  branchQuote: BranchQuote | null;

  constructor(
    quoteId: string,
    quote: Quote | null,
    quotePrice: QuotePrice | null,
    quotePresentation: QuotePresentation | null,
    siblings: QuoteSetSibling[] | null,
    items: QuoteItem[] | null,
    itemsData: QuoteItemProviderData[] | null,
    itemsBuyInData: QuoteItemBuyInData[] | null,
    itemPrices: QuoteItemPrice[] | null,
    branchQuote: BranchQuote | null,
    quoteProviderData: QuoteProviderData | null = null,
    isNewQuote = false
  ) {
    super(
      quoteId,
      quote,
      quotePrice,
      quotePresentation,
      siblings,
      items,
      itemsData,
      itemsBuyInData,
      itemPrices,
      quoteProviderData,
      isNewQuote
    );
    this.branchQuote = branchQuote;
  }
}
