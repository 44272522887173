//this will be a wrapper to the different logical areas... if needed.
//in reality it may not be necessary due to the layered design

import { emptyGuid } from '../api/guid';

export interface QuoteSupplier {
  //GUID which is from GlobalSupplier on license server
  supplierId: string;
  description: string;
  online?: boolean;
}

export type QuoteSupplierEvent = (refreshList: boolean) => Promise<QuoteSupplier[]>;
let _callback: QuoteSupplierEvent = async (_refreshList: boolean) => [];
export function setQuoteSupplierProviderCallback(callback: QuoteSupplierEvent) {
  _callback = callback;
}
export function isMultiSupplier(): boolean {
  return _isMulti;
}
let _isMulti = false;
let _primarySupplierId = emptyGuid;
export function primarySupplierId(): string {
  return _primarySupplierId;
}
export function singleSupplierId(): string | null {
  return _isMulti ? null : _primarySupplierId;
}

export async function getQuoteSuppliers(refreshList = false): Promise<QuoteSupplier[]> {
  const suppliers = await _callback(refreshList);
  _isMulti = suppliers.length > 1;
  _primarySupplierId = suppliers[0]?.supplierId;
  return suppliers;
}
