import { clone, compare } from '../../components/clone';
import { tlang } from '@softtech/webmodule-components';
import { fireQuickSuccessToast } from '../../toast-away';
import {
  InputUpdateProjectStateChangeReason,
  Project,
  ProjectState,
  Resource,
  ViewProjectDocument
} from '../../api/dealer-api-interface-project';
import { ProjectApi } from '../../api/project-api';
import { QuoteApi } from '../../api/quote-api';
import { getApiFactory } from '../../api/api-injector';
import { getProjectNumberFormatted } from '../../dealer-franchisee/projects/data/project-helper-functions';
import { EventBoolean } from '../../components/ui/events';
import { runEventNotify } from '../../components/array-helper';

export class ProjectContainer {
  projectId: string;
  project: Project | null;
  resources: Resource[] | null;
  documents: ViewProjectDocument[] | null;

  constructor(
    projectId: string,
    project: Project | null,
    resources: Resource[] | null,
    documents: ViewProjectDocument[] | null
  ) {
    this.projectId = projectId;
    this.project = project;
    this.resources = resources;
    this.documents = documents;
  }
}

export type EventNotify = () => Promise<void>;

export class ProjectContainerManager {
  private forceReload = false;
  clearContainer() {
    this.forceReload = true;
  }
  backup: ProjectContainer;
  container: ProjectContainer;
  api: ProjectApi = getApiFactory().project();
  quoteApi: QuoteApi = getApiFactory().quote();
  afterSave: EventNotify[] = [];
  forceLocked?: EventBoolean;
  stateChangeReason: InputUpdateProjectStateChangeReason | null = null;

  constructor(original: ProjectContainer, projectApi: ProjectApi) {
    this.api = projectApi;

    if (original.project && original.project.id !== original.projectId)
      throw new Error(`Invalid argument Project Id must match projectId`);

    this.container = original;
    this.backup = this.clone(this.container);
  }

  /**
   * the id for this managed container
   */
  get projectId(): string {
    return this.container.projectId;
  }

  /**
   * returns the project object after needsProject is called, or throws an error if the project is unavailable.
   */
  get project(): Project {
    if (!this.container.project) {
      throw new Error(tlang`Project is null`);
    }
    return this.container.project;
  }

  public get projectTitle(): string {
    return tlang`#${getProjectNumberFormatted(this.project)} - ${this.project.title}`;
  }

  /**
   * Simple wrapper around structuredClone
   * @param item an object of any basic type to clone
   * @returns
   */
  clone<ItemType>(item: ItemType): ItemType {
    return clone(item);
  }

  /**
   * this will ensure at an async level that the project property is valid, before accessing the property synchronously
   * @returns true if the project property is now valid
   */
  async needsProject(force?: boolean): Promise<boolean> {
    if (!this.container.project || force || this.forceReload) {
      this.forceReload = false;
      const result = await this.api.getProject({
        projectId: this.projectId
      });
      if (result) {
        this.resetProject(result.project, result.resources, result.documents);
      } else return false;
    }
    return true;
  }

  /**
   * this is called to send all the project information to the server.
   */
  public async saveProject(silently?: boolean): Promise<boolean> {
    const result = await this.api.updateProject({
      project: this.project,
      stateChangeReason: this.stateChangeReason
    });
    if (result) {
      this.resetProject(result.project, result.resources, result.documents);
      if (!silently) fireQuickSuccessToast(tlang`%%project%% Saved "${this.projectTitle}"`);

      await this.doAfterSave();
      return true;
    }
    return false;
  }

  public async removeDocument(id: string): Promise<boolean> {
    function remove(documents: ViewProjectDocument[] | null) {
      if (documents) {
        const idx = documents.findIndex(x => x.documentTracker.id === id) ?? -1;
        if (idx >= 0) documents.splice(idx, 1);
      }
    }

    await this.needsProject();

    const result = await this.api.deleteProjectDocument({ id });

    if (result?.successful) {
      remove(this.container.documents);
      remove(this.backup.documents);

      this.resetProject(this.project, this.container.resources ?? [], this.container.documents ?? []);

      await this.doAfterSave();
      return true;
    }

    return false;
  }

  /**
   * checks if there were any changes made
   */
  projectChanged(): boolean {
    return !compare(this.backup.project, this.container.project);
  }

  public changed(): boolean {
    return !compare(this.backup.project, this.container.project);
  }

  public get isLockedFromUse(): boolean {
    return this.forceLocked?.() || false;
  }

  public isReadonly(): boolean {
    return this.project.state != ProjectState.Active || this.isLockedFromUse;
  }

  public async canChangeClient(): Promise<boolean> {
    await this.needsProject();

    if (
      (!this.container.documents || this.container.documents.length == 0) &&
      (!this.container.resources || this.container.resources.length == 0)
    )
      return true;

    return false;
  }

  /**
   * replaces the backups and originals of objects with this new set of objects to become the master
   * @param project
   * @param resources
   * @param documents
   */
  private resetProject(project: Project, resources: Resource[], documents: ViewProjectDocument[]) {
    this.container.project = project;
    this.backup.project = this.clone(project);

    this.container.resources = resources;
    this.backup.resources = this.clone(resources);

    this.container.documents = documents;
    this.backup.documents = this.clone(documents);
  }

  /**
   * execute all bound events after any save operation to allow for re-rendering and refreshing of state
   */
  private async doAfterSave(): Promise<void> {
    await runEventNotify(this.afterSave);
  }
}
